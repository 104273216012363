
.right, .belege td {
  text-align: right;
}

.oposList table {
  width: 100%;
}

.belege {
  width: 100%;
  td {
    width: 50%;
    padding: 0 !important;
    background: none !important;
  }
  margin-bottom: 0;
}

.oposList table td, .oposList table th {
  padding: 4px 10px
}

.oposList table tr:nth-child(even) td {
  background: #eeeeee
}
